// Wordpress CSS
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

//Chakra UI
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { IconCaretLeft, IconCaretRight } from "@tabler/icons"
import { areThereAnyTopics, getWordCount } from "../components/helpers"
import { graphql, navigate } from "gatsby"

import { Dots } from "../components/dots"
//Components 
import Layout from "../layout"
//React
import React from "react"
import Seo from "../components/seo"
import parse from "html-react-parser"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const topics = post.topics.nodes

  const headings = post.blocks
  const counter = 0

  const featuredImage = {
    data: post.featuredImage?.node?.sourceUrl,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <Box height="md" background={"linear-gradient(180deg, rgba(11, 17, 40, 0) 29.69%, #0B1128 97.4%), url(" + featuredImage.data + ")" } backgroundRepeat="no-repeat" backgroundSize="cover">
        <Container maxW="container.xl" height="inherit" >
          <VStack maxW="container.sm" pb="9" spacing="3" height="inherit" alignItems="flex-start" justifyContent="end">
            {areThereAnyTopics(topics)}
            <HStack mt="0px !important" mb="16px !important">
             
              {topics.map((topic, index, array) => {
                const lastElement = array.length - 1;
        
                if (lastElement === index) {
                  return <Text color="base.50" m='none' fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {topic.name}</Text>
                } else {
                 return <>
                    <Text color="base.50" m='none' fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {topic.name}</Text>
                    <Dots></Dots>
                  </>
                }
              })}
              </HStack>
              <Heading as="h1" size="2xl" color="base.50" fontWeight="300" lineHeight="48px" >{post.title} </Heading>
              <Stack direction="row" align="center">
                <Text color="base.50" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium" >{post.author.node.name}</Text>
                <Dots></Dots>
                <Text color="base.50" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium" > {post.date} </Text>       
                <Dots></Dots>
                <Text color="base.50" fontSize="xs" fontWeight="700" fontFamily="Gotham Medium"> {getWordCount(post.content) + " min read"}  </Text>
              </Stack> 
          </VStack>
        </Container>
      </Box>

      <Container maxW="container.xl" py="20">
        {/* CONTENT LEFT */}
        <Grid  templateColumns='repeat(3, 1fr)' gap={28}>
          <GridItem colSpan={{base:'3', lg:'2'}}>
            <div class="s9-widget-wrapper"></div>
            <Box fontSize="20px !important" fontWeight="700"> {parse(post.excerpt)} </Box>
            <Divider borderBottomWidth="0px" height="2px" width="200px" backgroundColor="#D7D8E0" opacity="1" my="20"></Divider>
            <Text fontWeight="700"> {parse(post.content)} </Text>

            {/* Contact Button */}
            <Box display="flex" justifyContent="center" mt="14">
              <Button onClick={() => { navigate("/contact/") }} variant="solid" backgroundColor="#3554CA" color="#fff" w="full">Contact Us</Button>
            </Box>
            
            <Divider borderBottomWidth="0px" height="2px" backgroundColor="#D7D8E0" opacity="1" mt="14"></Divider>

            {/* PREVIOUS AND NEXT ARTICLE */}
            <Flex direction="row" alignItems="center" justifyContent="space-between"  pt="32px">
               <VStack alignItems="flex-start" fontSize="14px">
                {previous && (<Link href={"/news" + previous.uri} rel="prev" color="#2D3748 !important" textAlign="left" fontWeight="700">{parse(previous.title)}</Link>)}
                {previous && (<Link href={"/news" + previous.uri}><Button leftIcon={<IconCaretLeft height="16px" width="16px" alignItems="center" />} colorScheme='teal' variant='outline'>Previous</Button></Link>)}
              </VStack>
              <VStack alignItems="flex-end" fontSize="14px">
                {next && (<Link href={"/news" + next.uri} rel="next" color="#2D3748 !important" textAlign="right" fontWeight="700">{parse(next.title)}</Link>)}
                {next && (<Link href={"/news" + next.uri}><Button rightIcon={<IconCaretRight height="16px" width="16px" alignItems="center" />} colorScheme='teal' variant='outline'>Next</Button></Link>)}
              </VStack>
            </Flex>
          </GridItem>
         
           {!isMobile && (
            <GridItem colSpan={1} position="sticky" top="112px" alignSelf="start">
              <Text color="gray.600" fontFamily="Gotham Medium" fontWeight="bold" pb="16px"> Table of Contents </Text>
              <List>
                {headings.map((h, i = 0) => {
                
                if (h.tagName === "h1" || h.tagName === "h2") {
                  return <Link href={"#heading" + i++}><ListItem color="gray.600" fontWeight="700" pb="16px" fontSize="14px" >{h.innerHtml}</ListItem> </Link>  
                  } else if( h.tagName === "h3" ){
                    return <Link href={"#heading" + i++}><ListItem color="gray.600" fontWeight="700" pb="16px" pl={4} fontSize="14px">{h.innerHtml}</ListItem> </Link>
                } else {
                  <></>
                  }
                })}
              </List>
            </GridItem>
            )}
         
        </Grid>
      </Container>    
    </Layout>
  )
}

export default BlogPostTemplate

//GRAPHQL
export const pageQuery = graphql`
  query PostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      blocks {
        tagName
        innerHtml
      }
      author {
        node {
          name
        }
      }
      topics {
          nodes {
            name
          }
        }
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
